import * as React from 'react';
import { ArrowRightIcon } from '../../../../components/Icons/ArrowRightIcon';
import { __ } from '../../../../helpers/TranslationService';
import * as S from './../styled';
import { SlideEventTracker } from '..';

interface IHomeGalleryAction {
    link: string;
    text: string;
    buttonType: string;
    eventTracker: ({ index, buttonType }: SlideEventTracker) => void;
}

export const HomeGalleryAction = (props: IHomeGalleryAction) => {
    const { link, text, buttonType, eventTracker } = props;
    return (
        <S.HomeGallery_Action>
            <div onClick={() => eventTracker({ index: null, buttonType: buttonType })}>
                <a href={link} className="blue_button minimal has_hover with_arrow w-100 link">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    ></span>
                    <ArrowRightIcon width={15} height={25} />
                </a>
            </div>
        </S.HomeGallery_Action>
    );
};

